import React, { useState, useEffect, createContext, useContext } from 'react'

export const Favorites = createContext()

export const FavoritesProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [favoriteItems, setFavoriteItems] = useState([])

  useEffect(() => {
    setFavoriteItems(JSON.parse(localStorage.getItem('favorites') || '[]'))
    setIsLoaded(true)
  }, [])

  const toggleAsFavorite = item => {
    if (favoriteItems.includes(item)) {
      removeFavorite(item)
    } else {
      addFavorite(item)
    }
  }

  const addFavorite = item => {
    const arr = favoriteItems.concat([item])
    setFavoriteItems(arr)
    localStorage.setItem('favorites', JSON.stringify(arr))
  }

  const removeFavorite = item => {
    const arr = favoriteItems.filter(k => k !== item)
    setFavoriteItems(arr)
    localStorage.setItem('favorites', JSON.stringify(arr))
  }

  const hasFavorites = !!favoriteItems.length

  return (
    <Favorites.Provider
      value={{
        hasFavorites,
        addFavorite,
        removeFavorite,
        toggleAsFavorite,
        favoriteItems,
        isLoaded
      }}
    >
      {children}
    </Favorites.Provider>
  )
}

export const useFavorites = () => useContext(Favorites)
