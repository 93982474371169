import React, { createContext, useState } from 'react'

const initialValues = {
  isOpen: false,
  templateType: 'drug',
  content: {},
  runModal () {},
  close () {}
}

const ModalContext = createContext(initialValues)

const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(initialValues.isOpen)
  const [templateType, setTemplateType] = useState(initialValues.templateType)
  const [content, setContent] = useState(initialValues.content)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const runModal = (template, content) => {
    setTemplateType(template)
    setContent(content)
    open()
  }

  return (
    <ModalContext.Provider
      value={{ isOpen, runModal, close, templateType, content }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }
