// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js": () => import("./../../../../node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/Directory/Directory.js" /* webpackChunkName: "component---node-modules-kogk-gatsby-plugin-ui-lib-dist-src-components-directory-directory-js" */),
  "component---src-components-sections-all-brands-all-brands-mdx": () => import("./../../../src/components/sections/AllBrands/AllBrands.mdx" /* webpackChunkName: "component---src-components-sections-all-brands-all-brands-mdx" */),
  "component---src-components-sections-allar-verslanir-allar-verslanir-mdx": () => import("./../../../src/components/sections/AllarVerslanir/AllarVerslanir.mdx" /* webpackChunkName: "component---src-components-sections-allar-verslanir-allar-verslanir-mdx" */),
  "component---src-components-sections-banner-image-banner-image-mdx": () => import("./../../../src/components/sections/BannerImage/BannerImage.mdx" /* webpackChunkName: "component---src-components-sections-banner-image-banner-image-mdx" */),
  "component---src-components-sections-blue-content-section-blue-content-section-mdx": () => import("./../../../src/components/sections/BlueContentSection/BlueContentSection.mdx" /* webpackChunkName: "component---src-components-sections-blue-content-section-blue-content-section-mdx" */),
  "component---src-components-sections-custom-slider-custom-slider-mdx": () => import("./../../../src/components/sections/CustomSlider/CustomSlider.mdx" /* webpackChunkName: "component---src-components-sections-custom-slider-custom-slider-mdx" */),
  "component---src-components-sections-faq-faq-mdx": () => import("./../../../src/components/sections/FAQ/FAQ.mdx" /* webpackChunkName: "component---src-components-sections-faq-faq-mdx" */),
  "component---src-components-sections-featured-categories-featured-categories-mdx": () => import("./../../../src/components/sections/FeaturedCategories/FeaturedCategories.mdx" /* webpackChunkName: "component---src-components-sections-featured-categories-featured-categories-mdx" */),
  "component---src-components-sections-featured-text-slice-featured-text-slice-mdx": () => import("./../../../src/components/sections/FeaturedTextSlice/FeaturedTextSlice.mdx" /* webpackChunkName: "component---src-components-sections-featured-text-slice-featured-text-slice-mdx" */),
  "component---src-components-sections-filter-editor-filter-editor-mdx": () => import("./../../../src/components/sections/FilterEditor/FilterEditor.mdx" /* webpackChunkName: "component---src-components-sections-filter-editor-filter-editor-mdx" */),
  "component---src-components-sections-get-favorites-get-favorites-mdx": () => import("./../../../src/components/sections/GetFavorites/GetFavorites.mdx" /* webpackChunkName: "component---src-components-sections-get-favorites-get-favorites-mdx" */),
  "component---src-components-sections-hero-hero-mdx": () => import("./../../../src/components/sections/Hero/Hero.mdx" /* webpackChunkName: "component---src-components-sections-hero-hero-mdx" */),
  "component---src-components-sections-hero-shorter-hero-shorter-mdx": () => import("./../../../src/components/sections/HeroShorter/HeroShorter.mdx" /* webpackChunkName: "component---src-components-sections-hero-shorter-hero-shorter-mdx" */),
  "component---src-components-sections-hero-slider-hero-slider-mdx": () => import("./../../../src/components/sections/HeroSlider/HeroSlider.mdx" /* webpackChunkName: "component---src-components-sections-hero-slider-hero-slider-mdx" */),
  "component---src-components-sections-map-map-mdx": () => import("./../../../src/components/sections/Map/Map.mdx" /* webpackChunkName: "component---src-components-sections-map-map-mdx" */),
  "component---src-components-sections-mobile-filters-mobile-filters-mdx": () => import("./../../../src/components/sections/MobileFilters/MobileFilters.mdx" /* webpackChunkName: "component---src-components-sections-mobile-filters-mobile-filters-mdx" */),
  "component---src-components-sections-popular-prod-slider-popular-prod-slider-mdx": () => import("./../../../src/components/sections/PopularProdSlider/PopularProdSlider.mdx" /* webpackChunkName: "component---src-components-sections-popular-prod-slider-popular-prod-slider-mdx" */),
  "component---src-components-sections-product-carousel-product-carousel-mdx": () => import("./../../../src/components/sections/ProductCarousel/ProductCarousel.mdx" /* webpackChunkName: "component---src-components-sections-product-carousel-product-carousel-mdx" */),
  "component---src-components-sections-product-overview-product-overview-mdx": () => import("./../../../src/components/sections/ProductOverview/ProductOverview.mdx" /* webpackChunkName: "component---src-components-sections-product-overview-product-overview-mdx" */),
  "component---src-components-sections-rich-text-rich-text-mdx": () => import("./../../../src/components/sections/RichText/RichText.mdx" /* webpackChunkName: "component---src-components-sections-rich-text-rich-text-mdx" */),
  "component---src-components-sections-search-field-search-field-mdx": () => import("./../../../src/components/sections/SearchField/SearchField.mdx" /* webpackChunkName: "component---src-components-sections-search-field-search-field-mdx" */),
  "component---src-components-sections-search-input-section-search-input-section-mdx": () => import("./../../../src/components/sections/SearchInputSection/SearchInputSection.mdx" /* webpackChunkName: "component---src-components-sections-search-input-section-search-input-section-mdx" */),
  "component---src-components-sections-staff-section-staff-section-mdx": () => import("./../../../src/components/sections/StaffSection/StaffSection.mdx" /* webpackChunkName: "component---src-components-sections-staff-section-staff-section-mdx" */),
  "component---src-components-sections-stripped-slider-stripped-slider-mdx": () => import("./../../../src/components/sections/StrippedSlider/StrippedSlider.mdx" /* webpackChunkName: "component---src-components-sections-stripped-slider-stripped-slider-mdx" */),
  "component---src-components-sections-subpage-hero-subpage-hero-mdx": () => import("./../../../src/components/sections/SubpageHero/SubpageHero.mdx" /* webpackChunkName: "component---src-components-sections-subpage-hero-subpage-hero-mdx" */),
  "component---src-components-site-burger-burger-mdx": () => import("./../../../src/components/site/Burger/Burger.mdx" /* webpackChunkName: "component---src-components-site-burger-burger-mdx" */),
  "component---src-components-site-button-button-mdx": () => import("./../../../src/components/site/Button/Button.mdx" /* webpackChunkName: "component---src-components-site-button-button-mdx" */),
  "component---src-components-site-button-link-button-link-mdx": () => import("./../../../src/components/site/ButtonLink/ButtonLink.mdx" /* webpackChunkName: "component---src-components-site-button-link-button-link-mdx" */),
  "component---src-components-site-card-with-button-card-with-button-mdx": () => import("./../../../src/components/site/CardWithButton/CardWithButton.mdx" /* webpackChunkName: "component---src-components-site-card-with-button-card-with-button-mdx" */),
  "component---src-components-site-cart-btn-mobile-cart-btn-mobile-mdx": () => import("./../../../src/components/site/CartBtnMobile/CartBtnMobile.mdx" /* webpackChunkName: "component---src-components-site-cart-btn-mobile-cart-btn-mobile-mdx" */),
  "component---src-components-site-cart-summary-cart-summary-mdx": () => import("./../../../src/components/site/CartSummary/CartSummary.mdx" /* webpackChunkName: "component---src-components-site-cart-summary-cart-summary-mdx" */),
  "component---src-components-site-favorite-btn-favorite-btn-mdx": () => import("./../../../src/components/site/FavoriteBtn/FavoriteBtn.mdx" /* webpackChunkName: "component---src-components-site-favorite-btn-favorite-btn-mdx" */),
  "component---src-components-site-form-info-container-form-info-container-mdx": () => import("./../../../src/components/site/FormInfoContainer/FormInfoContainer.mdx" /* webpackChunkName: "component---src-components-site-form-info-container-form-info-container-mdx" */),
  "component---src-components-site-input-with-dropdown-input-with-dropdown-mdx": () => import("./../../../src/components/site/InputWithDropdown/InputWithDropdown.mdx" /* webpackChunkName: "component---src-components-site-input-with-dropdown-input-with-dropdown-mdx" */),
  "component---src-components-site-menu-desktop-menu-desktop-mdx": () => import("./../../../src/components/site/MenuDesktop/MenuDesktop.mdx" /* webpackChunkName: "component---src-components-site-menu-desktop-menu-desktop-mdx" */),
  "component---src-components-site-menu-mobile-menu-mobile-mdx": () => import("./../../../src/components/site/MenuMobile/MenuMobile.mdx" /* webpackChunkName: "component---src-components-site-menu-mobile-menu-mobile-mdx" */),
  "component---src-components-site-pickup-disclaimer-pickup-disclaimer-mdx": () => import("./../../../src/components/site/PickupDisclaimer/PickupDisclaimer.mdx" /* webpackChunkName: "component---src-components-site-pickup-disclaimer-pickup-disclaimer-mdx" */),
  "component---src-components-site-progress-bar-progress-bar-mdx": () => import("./../../../src/components/site/ProgressBar/ProgressBar.mdx" /* webpackChunkName: "component---src-components-site-progress-bar-progress-bar-mdx" */),
  "component---src-components-site-requires-login-requires-login-mdx": () => import("./../../../src/components/site/RequiresLogin/RequiresLogin.mdx" /* webpackChunkName: "component---src-components-site-requires-login-requires-login-mdx" */),
  "component---src-components-site-seo-seo-mdx": () => import("./../../../src/components/site/SEO/SEO.mdx" /* webpackChunkName: "component---src-components-site-seo-seo-mdx" */),
  "component---src-components-site-user-insurance-step-user-insurance-step-mdx": () => import("./../../../src/components/site/UserInsuranceStep/UserInsuranceStep.mdx" /* webpackChunkName: "component---src-components-site-user-insurance-step-user-insurance-step-mdx" */),
  "component---src-components-site-user-orders-user-orders-mdx": () => import("./../../../src/components/site/UserOrders/UserOrders.mdx" /* webpackChunkName: "component---src-components-site-user-orders-user-orders-mdx" */),
  "component---src-components-site-validation-message-validation-message-mdx": () => import("./../../../src/components/site/ValidationMessage/ValidationMessage.mdx" /* webpackChunkName: "component---src-components-site-validation-message-validation-message-mdx" */),
  "component---src-components-site-verslun-verslun-mdx": () => import("./../../../src/components/site/Verslun/Verslun.mdx" /* webpackChunkName: "component---src-components-site-verslun-verslun-mdx" */),
  "component---src-components-templates-brand-page-brand-page-js": () => import("./../../../src/components/templates/BrandPage/BrandPage.js" /* webpackChunkName: "component---src-components-templates-brand-page-brand-page-js" */),
  "component---src-components-templates-category-page-category-page-js": () => import("./../../../src/components/templates/CategoryPage/CategoryPage.js" /* webpackChunkName: "component---src-components-templates-category-page-category-page-js" */),
  "component---src-components-templates-checkout-thank-you-checkout-thank-you-mdx": () => import("./../../../src/components/templates/CheckoutThankYou/CheckoutThankYou.mdx" /* webpackChunkName: "component---src-components-templates-checkout-thank-you-checkout-thank-you-mdx" */),
  "component---src-components-templates-favorites-page-favorites-page-mdx": () => import("./../../../src/components/templates/FavoritesPage/FavoritesPage.mdx" /* webpackChunkName: "component---src-components-templates-favorites-page-favorites-page-mdx" */),
  "component---src-components-templates-member-signup-member-signup-mdx": () => import("./../../../src/components/templates/MemberSignup/MemberSignup.mdx" /* webpackChunkName: "component---src-components-templates-member-signup-member-signup-mdx" */),
  "component---src-components-templates-page-page-js": () => import("./../../../src/components/templates/Page/Page.js" /* webpackChunkName: "component---src-components-templates-page-page-js" */),
  "component---src-components-templates-product-page-product-page-js": () => import("./../../../src/components/templates/ProductPage/ProductPage.js" /* webpackChunkName: "component---src-components-templates-product-page-product-page-js" */),
  "component---src-components-templates-view-template-view-template-mdx": () => import("./../../../src/components/templates/ViewTemplate/ViewTemplate.mdx" /* webpackChunkName: "component---src-components-templates-view-template-view-template-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-karfa-js": () => import("./../../../src/pages/karfa.js" /* webpackChunkName: "component---src-pages-karfa-js" */),
  "component---src-pages-lyfjagatt-js": () => import("./../../../src/pages/lyfjagatt.js" /* webpackChunkName: "component---src-pages-lyfjagatt-js" */),
  "component---src-pages-minar-vorur-js": () => import("./../../../src/pages/minar-vorur.js" /* webpackChunkName: "component---src-pages-minar-vorur-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-takk-fyrir-js": () => import("./../../../src/pages/takk-fyrir.js" /* webpackChunkName: "component---src-pages-takk-fyrir-js" */),
  "component---src-pages-viewtemplate-js": () => import("./../../../src/pages/viewtemplate.js" /* webpackChunkName: "component---src-pages-viewtemplate-js" */),
  "component---src-pages-vorur-js": () => import("./../../../src/pages/vorur.js" /* webpackChunkName: "component---src-pages-vorur-js" */)
}

