import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import Mutex from '../utils/mutex'
import { useApolloClient, useMutation } from '@apollo/client'
import { GET_CART, ADD_TO_CART } from '@src/data/backend/queries'
import { useAuthentication } from './Authentication'

const CART_TOKEN = 'cartToken'

// The cart state is managed by the server, but stored on the client, i.e.
// async requestToServer(state, action) -> newState
// If we'd execute multiple requests a the same time, both will send the same
// state to the server, get different states back, and override the newStates
// from each other
const mutex = new Mutex()

const sleep = ms => new Promise(r => setTimeout(r, ms))

const emptyCart = () => ({
  token: null,
  products: [],
  prescriptions: [],
  totalPrice: 0,
  totalDiscount: 0
})

const CartContext = createContext({
  cart: emptyCart(),
  addProductToCart: () => {},
  addPrescriptionToCart: () => {},
  changeQuantity: () => {}
})

const getToken = () => localStorage.getItem(CART_TOKEN)
const setToken = token => localStorage.setItem(CART_TOKEN, token)

export const useCartState = () => useContext(CartContext)

export const useCartActions = ({ onAddedToCart } = {}) => {
  const { cart, setCart } = useCartState()
  const [numLoading, setNumLoading] = useState(0)

  const [doAddToCart, addToCartResponse] = useMutation(ADD_TO_CART, {
    onCompleted: ({ addToCart: response }) => {
      if (response.__typename === 'Cart') {
        setCart(response)
        onAddedToCart && onAddedToCart()
      }
    }
  })

  const r = addToCartResponse?.data?.addToCart
  const error =
    addToCartResponse?.error ??
    (r?.__typename === 'ErrorMessage' ? r : undefined)

  const addToCart = useCallback(
    (type, item) => {
      setNumLoading(n => n + 1)
      return mutex.synchronize(async () => {
        // we need to make sure previous setCart() calls have flushed the
        // cart token to localStorage
        await sleep(40)

        return doAddToCart({
          variables: {
            input: {
              token: getToken(),
              [type]: item
            }
          }
        }).finally(() => setNumLoading(n => n - 1))
      })
    },
    [doAddToCart]
  )

  const addProductToCart = useCallback(x => addToCart('product', x), [
    addToCart
  ])

  const addPrescriptionToCart = useCallback(x => addToCart('prescription', x), [
    addToCart
  ])

  const addPromoCode = useCallback(x => addToCart('promoCode', x), [addToCart])

  const changeQuantity = useCallback(
    (id, quantity) => {
      const cartProduct = cart.products.find(item => item.id === id)
      if (cartProduct) {
        addProductToCart({
          productId: cartProduct.id,
          productGroupId: cartProduct.productGroup.id,
          quantity
        })
      }

      const cartPrescription = cart.prescriptions.find(pre => pre.id === id)
      if (cartPrescription) {
        const { userId, productId } = cartPrescription.prescription
        addPrescriptionToCart({
          id,
          quantity,
          userId,
          productId
        })
      }
    },
    [cart, addProductToCart, addPrescriptionToCart]
  )

  return {
    loading: numLoading > 0,
    error,
    addProductToCart,
    addPrescriptionToCart,
    addPromoCode,
    changeQuantity
  }
}

export const CartProvider = ({ children }) => {
  const auth = useAuthentication()
  const client = useApolloClient()
  const [cart, setCart] = useState(emptyCart())
  const initialized = useRef(false)

  // flush token to localstorage every time the cart changes
  useEffect(() => {
    if (initialized.current) setToken(cart.token ?? '')
    initialized.current = true
  }, [cart])

  // fetches initial cart on page load, and re-fetches cart if user scheme/club
  // changes, which might affect discounts the user receives
  useEffect(() => {
    // if the user is being fetched we wait until it's done
    if (auth.loading) {
      return
    }

    mutex.synchronize(async () => {
      const token = getToken()

      // the cart is empty if there is no token, so don't bother the api
      if (!token) {
        setCart(emptyCart())
        return
      }

      const { data } = await client.query({
        fetchPolicy: 'no-cache',
        query: GET_CART,
        variables: { token }
      })
      data?.cart && setCart(data.cart)
    })
  }, [auth.loading, auth.user?.scheme])

  // since the cart is stored client side, the client is responsible for
  // clearing the cart after successful checkout
  const clearCart = useCallback(() => {
    mutex.synchronize(async () => {
      setCart(emptyCart())
    })
  }, [setCart])

  // computed attributes
  const { quantities, cartSize } = useMemo(() => {
    const allItems = [...cart.products, ...cart.prescriptions]
    return {
      quantities: allItems.reduce(
        (acc, { id, quantity }) => ({ ...acc, [id]: quantity }),
        {}
      ),
      cartSize: allItems.reduce((sum, x) => sum + x.quantity, 0)
    }
  }, [cart])

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        cartToken: cart.token,
        clearCart,
        quantities,
        cartSize
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
