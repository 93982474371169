import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createHttpLink } from 'apollo-link-http'
import '@src/styles/index.scss'
// font awesome flashy huge icons on load FIX
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { MenuTypeProvider } from '@src/context/MenuTypeContext'
import { FooterVisibilityProvider } from '@src/context/FooterVisibilityContext'
import { CartVisibilityProvider } from '@src/context/CartVisibility'
import { FavoritesProvider } from '@src/context/Favorites'
import { ModalProvider } from '@src/context/ModalContext'
import { ViewTemplateProvider } from '@src/context/ViewTemplateContext'
import { AuthenticationProvider } from './src/context/Authentication'
import { CartProvider } from './src/context/Cart'

config.autoAddCss = false

const API_ROOT_URL = process.env.GATSBY_API_ROOT_URL

Bugsnag.start({
  apiKey: '29b4a46111794cffec94a731fa60a6d8',
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
  plugins: [new BugsnagPluginReact()],
  collectUserIp: false
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const httpLink = createHttpLink({
  uri: API_ROOT_URL + '/api/graphql',
  credentials: 'include'
})

const headersLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Comp': 'LYF_OG_HEILSA',
      // store id of lyf og heilsa kringlunni, used when fetching inventory status
      'X-Store': 'S015'
    }
  }
})

const client = new ApolloClient({
  link: headersLink.concat(httpLink),
  cache: new InMemoryCache()
})

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <CartProvider>
          <ModalProvider>
            <ViewTemplateProvider>
              <MenuTypeProvider>
                <CartVisibilityProvider>
                  <FavoritesProvider>
                    <FooterVisibilityProvider>{element}</FooterVisibilityProvider>
                  </FavoritesProvider>
                </CartVisibilityProvider>
              </MenuTypeProvider>
            </ViewTemplateProvider>
          </ModalProvider>
        </CartProvider>
      </AuthenticationProvider>
    </ApolloProvider>
  </ErrorBoundary>
)
