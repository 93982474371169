import React, { createContext, useState, useContext } from 'react'

export const CartVisibility = createContext()

export const CartVisibilityProvider = ({ children }) => {
  const [cartVisible, setCartVisible] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const modifyCartVisibility = show => {
    setCartVisible(show)
  }

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <CartVisibility.Provider
      value={{ cartVisible, modifyCartVisibility, isOpen, open, close, toggle }}
    >
      {children}
    </CartVisibility.Provider>
  )
}

export const useCartVisibility = () => useContext(CartVisibility)
