import { gql } from '@apollo/client'

export const GET_PRODUCT_COUNT = gql`
  query GetInventory($id: ID!) {
    navproduct(id: $id) {
      id
      inventoryCount
    }
  }
`

export const GET_VIEWER = gql`
  query GetViewer {
    viewer {
      id
      isOfMinimumAge
    }
  }
`

export const GET_VIEWER_DETAILS = gql`
  query GetViewerDetails {
    viewer {
      id
      phone
      isOfMinimumAge
      scheme
      children {
        id
        name
      }
      details {
        id
        name
        address
        postCode
      }
    }
  }
`

export const GET_VIEWER_INSURANCE = gql`
  query GetInsuranceStatus($userId: ID) {
    insuranceStatus(userId: $userId) {
      id
      stepIndex
      periodPayment
      stepPercent
      periodStartDate
      scheme
    }
  }
`

export const GET_PRESCRIPTIONS = gql`
  query GetPrescriptions($userId: ID) {
    prescriptions(userId: $userId) {
      id
      type
      userId
      productId
      measurement
      description
      instructions
      maxQuantity
      issuerName
      issuerNumber
      isAnimalPrescription
      createdAt
      lastValidDate
      withdrawsOriginal
      withdrawsRemaining
      quantityPerPackage
      pkgPerWithdrawal
      progress
      category
      nextWithdrawDate
      inventoryCount
      recentlyBought
    }
  }
`

export const GET_PRESCRIPTION_PRICE = gql`
  query GetPrescriptionPrice($id: ID!, $userId: ID) {
    prescription(id: $id, userId: $userId) {
      id
      price
    }
  }
`

export const GET_PRESCRIPTION_SUBSTITUTES = gql`
  query GetPrescriptionSubstitutes($id: ID!, $userId: ID) {
    prescription(id: $id, userId: $userId) {
      id
      samheitalyf {
        id
        description
        price
        inventoryCount
      }
    }
  }
`

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      id
      title
      subcategories {
        id
        title
      }
    }
  }
`

export const GET_PRODUCT_GROUP = gql`
  query GetProductGroup($id: ID!) {
    productGroup(id: $id) {
      id
      productGroupId: id
      title
      description
      shortDescription
      usage
      ingredients
      category {
        id
        categoryId: id
        title
        slug
      }
      subcategory {
        id
        subcategoryId: id
        title
      }
      products {
        seasonCode
        id
        productId: id
        title
        fullPrice: lyfOgHeilsaFullPrice
        discountPrice: lyfOgHeilsaDiscountPrice
        discountPercent: lyfOgHeilsaDiscountPercent
        maxQuantity
        images {
          id
          imageId: id
          largeUrl
        }
      }
    }
  }
`

export const GET_PRODUCT_GROUP_LIST = gql`
  query GetProductGroupList($input: ProductsInput) {
    productGroups(input: $input) {
      nodes {
        id
        title
        price: lyfOgHeilsaFullPrice
        discountPrice: lyfOgHeilsaDiscountPrice
        updatedAt
        createdAt
        lyfOgHeilsa
        apotekarinn
        tags
        category {
          id
          title
          slug
        }
        subcategory {
          id
          title
        }
        brand {
          id
          title
        }
        images {
          thumbnailUrl
        }
        products {
          id
          title
        }
      }
      aggregations {
        categories {
          text
          count
          category {
            id
            title
          }
        }
        subcategories {
          text
          count
          subcategory {
            id
            title
          }
        }
        brands {
          text
          count
          brand {
            id
            title
          }
        }
      }
    }
  }
`

export const PRODUCT_SEARCH = gql`
  query Search($input: ProductsInput) {
    productGroups(input: $input) {
      nodes {
        id
        title
        discountPrice: lyfOgHeilsaDiscountPrice
        fullPrice: lyfOgHeilsaFullPrice
        discountPercent: lyfOgHeilsaDiscountPercent
        category {
          id
          slug
        }
        images {
          id
          largeUrl
          thumbnailUrl
        }
      }
      pageInfo {
        page
        hasPrevPage
        hasNextPage
        totalResults
        totalPages
      }
      aggregations {
        minPrice
        maxPrice
        subcategories {
          count
          subcategory {
            id
            title
          }
        }
        brands {
          count
          brand {
            id
            title
          }
        }
      }
    }
  }
`

const cartFields = gql`
  fragment cartFields on Cart {
    token
    products {
      id
      quantity
      totalPrice
      totalDiscount
      discountPercent
      productGroup {
        category {
          id
          title
          slug
        }
        id
        title
        images {
          thumbnailUrl
        }
      }
      product {
        id
        title
        maxQuantity
      }
    }
    prescriptions {
      id
      quantity
      totalPrice
      totalDiscount
      discountPercent
      prescription {
        userId
        productId
        description
        maxQuantity
        inventoryCount
      }
    }
    totalPrice
    totalDiscount
    promoCode
    promoCodeDiscount
  }
`

export const GET_CART = gql`
  query GetCart($token: String) {
    cart(token: $token) {
      ...cartFields
    }
  }
  ${cartFields}
`

export const ADD_TO_CART = gql`
  mutation AddToCart($input: AddToCartInput!) {
    addToCart(input: $input) {
      ... on Cart {
        ...cartFields
      }
      ... on ErrorMessage {
        message
      }
    }
  }
  ${cartFields}
`

export const GET_ORDER = gql`
  query GET_ORDER($id: ID!) {
    order(id: $id) {
      id
      reference
      payment
    }
  }
`

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      ... on ThreeDsResponse {
        html
        redirect
      }
      ... on ErrorMessage {
        message
      }
    }
  }
`

export const SIGNUP = gql`
  mutation ClubSignup($input: SignupInput!) {
    signup(input: $input) {
      message
    }
  }
`

export const GET_POST_CODES = gql`
  query GET_POST_CODES {
    postCodes {
      id
      code
      town
    }
  }
`

export const GET_SHIPPING_OPTIONS = gql`
  query GET_SHIPPING_OPTIONS($input: ShippingOptionsInput!) {
    shippingOptions(input: $input) {
      type
      name
      description
      price
      variants {
        id
        name
      }
    }
  }
`
