import React, { createContext, useState } from 'react'

const MenuTypeContext = createContext()

const MenuTypeProvider = ({ children }) => {
  const [template, setTemplate] = useState({
    type: 'primary',
    title: '',
    isFrontpage: false
  })
  const [showUserIcon, setShowUserIcon] = useState(true)

  const modify = (type, title = '', isFrontpage = false) => {
    setTemplate({
      type,
      title,
      isFrontpage
    })
  }
  return (
    <MenuTypeContext.Provider value={{ template, modify, showUserIcon, setShowUserIcon }}>
      {children}
    </MenuTypeContext.Provider>
  )
}

export { MenuTypeContext, MenuTypeProvider }
