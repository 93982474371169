import React, { createContext, useState } from 'react'

const FooterVisibilityContext = createContext()

const FooterVisibilityProvider = ({ children }) => {
  const [showFooter, setShowFooter] = useState(true)

  const modifyVisibility = (show) => {
    setShowFooter(show)
  }

  return (
    <FooterVisibilityContext.Provider value={{ showFooter, modifyVisibility }}>
      {children}
    </FooterVisibilityContext.Provider>
  )
}

export { FooterVisibilityContext, FooterVisibilityProvider }
