import { useEffect } from 'react'
import throttle from 'lodash/throttle'

const events = [
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress'
]

export default (onIdle, timeout, enabled = true) => {
  useEffect(() => {
    if (!enabled) return

    let timer = setTimeout(onIdle, timeout)

    const resetTimer = throttle((e) => {
      clearTimeout(timer)
      timer = setTimeout(onIdle, timeout)
    }, 1000)

    events.forEach(k => {
      document.addEventListener(k, resetTimer)
    })

    return () => {
      clearTimeout(timeout)

      events.forEach(k => {
        document.removeEventListener(k, resetTimer)
      })
    }
  }, [onIdle, timeout, enabled])
}
