import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { GET_VIEWER } from '@src/data/backend/queries'
import useIdle from '@src/hooks/useIdle'
import { redirect, LOGOUT_PATH } from '../utils/index'

const IDLE_LOGOUT_TIMEOUT = 1000 * 60 * 15

const AuthenticationContext = createContext({
  user: null,
  loading: true,
  refetch: () => {}
})

export const useAuthentication = () => useContext(AuthenticationContext)

export const logout = e => {
  e?.preventDefault()
  redirect(LOGOUT_PATH, [['backTo', location.origin]])
}

export const AuthenticationProvider = ({ children }) => {
  const { data, previousData, loading, refetch } = useQuery(GET_VIEWER)
  const user = (data ?? previousData)?.viewer

  useIdle(logout, IDLE_LOGOUT_TIMEOUT, Boolean(user))

  return (
    <AuthenticationContext.Provider value={{ user, loading, refetch }}>
      {children}
    </AuthenticationContext.Provider>
  )
}
