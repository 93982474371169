import React, { createContext, useState } from 'react'

const ViewTemplateContext = createContext({
  templateType: 'login'
})

const ViewTemplateProvider = ({ children }) => {
  const [viewType, setViewType] = useState('login')
  const [content, setContent] = useState({})
  const [category, setCategory] = useState('')

  const setView = (view, content, category) => {
    setViewType(view)
    setContent(content)
    setCategory(category)
  }

  return (
    <ViewTemplateContext.Provider
      value={{ viewType, setView, content, category }}
    >
      {children}
    </ViewTemplateContext.Provider>
  )
}

export { ViewTemplateContext, ViewTemplateProvider }
